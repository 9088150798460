import "./Catalogue.scss";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import ProductsList from "../../components/productsList/ProductsList";
import { useState, useCallback, useContext, useEffect } from "react";
import {ReactComponent as RightArrow} from "./../../resources/right_arrow.svg";
import {ReactComponent as Cross} from "./../../resources/cross.svg";
import ReactPaginate from "react-paginate";
import UsersContext from "../../Context";

const Catalogue = ({type, pathName}) => {
    const {productsData} = useContext(UsersContext)
    
    const [sortingValue, setSortingValue] = useState(["За популярністю", "Спочатку дорожче", "Спочатку дешевше"]);
    const [categoryFilter, openCategory] = useState({brand: 1, category: 1, filter: 1});
    const [originProducts, setOriginProducts] = useState(productsData);
    const [filteredProducts, setFilteredProducts] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(()=>{
        type?setOriginProducts(productsData.filter((el) => {
            return el.type === type
        })):setOriginProducts(productsData)
    },[type,setOriginProducts, productsData]) 

    const uniqueRender = (arg) => {
        return [...new Set(originProducts.map((el) => {
            return el[arg]
        }))].map((el2,index) => {
            return (
                <div className="catalogue__products--filter-link" key={index}>
                    <p onClick={filterProducts} field={arg}>{el2}</p>
                    <Cross onClick={resetFilters}/>
                </div>
            )
        });
    };

    
    const filterProducts = (event) => {
        setFilteredProducts(originProducts.filter((el) => {
            return el.brand === event.target.innerHTML || el.category === event.target.innerHTML 
        }))

        Array.from(document.querySelectorAll(".active")).map(el=>el.classList.remove("active"))
        event.target.className = "active";
    }

    const resetFilters = () => {
        setFilteredProducts(originProducts);
        Array.from(document.querySelectorAll(".active")).map(el=>el.classList.remove("active"))
    }

    const changeFilter = useCallback((event)=>{
        switch(event.target.innerHTML){
            case "За популярністю":
                setSortingValue(["За популярністю", "Спочатку дорожче", "Спочатку дешевше"]);
                setFilteredProducts(filteredProducts?filteredProducts.sort((a,b) => b.quantity - a.quantity)
                :originProducts.sort((a,b) => b.quantity - a.quantity))
                break;
            case "Спочатку дорожче":
                setSortingValue(["Спочатку дорожче", "За популярністю", "Спочатку дешевше"])
                setFilteredProducts(filteredProducts?filteredProducts.sort((a,b) => (b.price*(1-b.discount/100)) - (a.price*(1-a.discount/100)))
                :originProducts.sort((a,b) => (b.price*(1-b.discount/100)) - (a.price*(1-a.discount/100))))
                break;
            case "Спочатку дешевше":
                setSortingValue(["Спочатку дешевше", "За популярністю", "Спочатку дорожче"])
                setFilteredProducts(filteredProducts?filteredProducts.sort((a,b) => (a.price*(1-a.discount/100)) - (b.price*(1-b.discount/100)))
                :originProducts.sort((a,b) => (a.price*(1-a.discount/100)) - (b.price*(1-b.discount/100))))
                break;
            default:
                break;
        }
    },[originProducts,filteredProducts])

    const PER_PAGE = 9;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(filteredProducts?filteredProducts.length / PER_PAGE: originProducts.length / PER_PAGE);

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
        window.scrollTo(0,0)
    }


    return <section className="catalogue">
        <Breadcrumbs links={[{path:"/catalogue", linkName: "Каталог"}, type?{path:"/catalogue/" + type, linkName: pathName}:""]}/>

        <div className="catalogue__filter">
            <h2>ФІЛЬТР</h2>

            <div onClick={()=>openCategory({...categoryFilter, filter: !categoryFilter.filter})} className={(categoryFilter.filter?"catalogue__products--closed ":"") + "catalogue__filter--sorting"}>
                <p>Сортувати:</p>
                
                <div className="catalogue__filter--list">
                    <h4 >{sortingValue[0]}</h4>
                    <h4 onClick={changeFilter}>{sortingValue[1]}</h4>
                    <h4 onClick={changeFilter}>{sortingValue[2]}</h4>
                </div>

                <RightArrow/>
            </div>
        </div>

        <div className="catalogue__products">
            <div className="catalogue__products--filter">
                <div className={(categoryFilter.brand?"catalogue__products--closed ":"") + "catalogue__products--brands"}>
                    <h3 onClick={()=>openCategory({...categoryFilter, brand: !categoryFilter.brand})} >Бренд <RightArrow/></h3>
                    {uniqueRender("brand")}
                </div>

                <div className={(categoryFilter.category?"catalogue__products--closed ":"") + "catalogue__products--categories"}>
                    <h3 onClick={()=>openCategory({...categoryFilter, category: !categoryFilter.category})}>Категорії <RightArrow/></h3>
                    {uniqueRender("category")}
                </div>

            </div>

            <ProductsList products={filteredProducts?filteredProducts.slice(offset, offset + PER_PAGE):originProducts.slice(offset, offset + PER_PAGE)}/>
        </div>
            {
                filteredProducts?filteredProducts.length>9?<ReactPaginate
                previousLabel={<RightArrow/>}
                nextLabel={<RightArrow/>}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
            />:"":originProducts.length>9?<ReactPaginate
            previousLabel={<RightArrow/>}
            nextLabel={<RightArrow/>}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
        />:""
            }
    </section>
}

export default Catalogue;