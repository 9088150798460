import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import "./Shipping.scss"


const Shipping = () => {

    return <section className="shipping">
        <Breadcrumbs links={[]} />

        <h1>Доставка і оплата</h1>


        <h2>Доставка:</h2>

        <p>Доставка товарів доступна двома зручними способами: самовивіз з нашого магазину або доставка через службу "Нова Пошта".</p>

        <h3>Самовивіз з магазину:</h3>

        <p>Адреса: вулиця Гоголя, 8 </p>
        <p>Графік роботи: щоденно з 10:00 до 20:00</p>
        <p>Замовлення, зроблені онлайн, можна буде забрати в магазині за вказаною адресою під час його робочого часу.</p>

        <h3>Доставка "Новою Поштою":</h3>

        <p>Зручна та надійна доставка через службу "Нова Пошта" доступна для всіх наших клієнтів. </p>
        <p>Терміни та вартість доставки розраховуються автоматично при оформленні замовлення.</p>


        <h2>Оплата:</h2>

        <p>Ми надаємо два варіанти оплати:</p>

        <h3>Оплата при отриманні:</h3>

        <p>Ви можете оплатити своє замовлення готівкою при отриманні товару під час самовивозу або кур'єру "Нової Пошти".</p>

        <h3>Переказ на картку:</h3>

        <p>Для зручності наших клієнтів ми приймаємо оплату замовлень за допомогою переказу на банківську картку. </p>
        <p>Реквізити для оплати будуть надані під час оформлення замовлення.</p> <br/>

        <p>Якщо у вас виникнуть будь-які питання або потрібна додаткова інформація, будь ласка, звертайтеся до нашої служби підтримки.</p>
        <p>Дякуємо, що обрали нас! Бажаємо приємних покупок.</p>
    </section>
}

export default Shipping;